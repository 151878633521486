import React from 'react';
import PropTypes from 'prop-types';
import { maybePluralize } from 'utils/utilities';
import { Modal } from '@springrole/trivia-games';
import styles from './Styles.module.scss';

const AccessRestrictedModal = (props) => {
  const { open, admins, loading, handleCancel, automationRestriction } = props;

  const getAdminNameText = () =>
    admins
      .map((admin) => admin.name)
      .join(', ')
      .replace(/, ([^,]*)$/, ' and $1');

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      loading={loading}
      title='Restricted access'
      confirmText=''
      cancelText='Close'
    >
      <div className={styles.contentWrapper}>
        {automationRestriction ? (
          <>
            <div>
              Uh-oh ☹️ <br />
              Unfortunately, you don&apos;t have permission to manage Game Automations on this
              workspace.
            </div>
            <div>
              Please ask your Trivia {maybePluralize(admins.length, 'admin')} -&nbsp;
              <b>{getAdminNameText()}</b>
              &nbsp;- to grant permissions to you or manage Game Automations on your behalf.
            </div>
          </>
        ) : (
          <>
            <div>
              Uh-oh ☹️ <br />
              Unfortunately, you don&apos;t have permission to create and run Customizable Quizzes
              on this workspace.
            </div>
            <div>
              Please ask your Trivia {maybePluralize(admins.length, 'admin')} -&nbsp;
              <b>{getAdminNameText()}</b>
              &nbsp;- to grant permissions to you or create schedule &amp; Edit or Delete Custom Quizzes on your behalf.
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

AccessRestrictedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  automationRestriction: PropTypes.bool.isRequired,
  admins: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      length: PropTypes.func,
      map: PropTypes.func
    })
  ).isRequired
};

export default AccessRestrictedModal;
